<!--
 * @Author: your name
 * @Date: 2021-08-16 16:15:49
 * @LastEditTime: 2022-01-01 14:00:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\views\member\views\list.vue
-->
<template>
  <div>
    <page-header title="预定管理"></page-header>
    <div class="container">
      <el-form :model="search"
               class="dtb-search-form"
               inline
               label-width="100px">
        <el-form-item label="活动主题：">
          <el-input v-model="search.subject"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label='手机号：'>
          <el-input v-model="search.mobile"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label='提交人手机号：'>
          <el-input v-model="search.user_mobile"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="活动时间：">
          <el-date-picker v-model="search.dates"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="至"
                          start-placeholder="开始日期"
                          end-placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="状态：">
          <el-select v-model="search.status"
                     placeholder="请选择">
            <el-option label="已提交"
                       :value="1"></el-option>
            <el-option label="已完成"
                       :value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="getList">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="list"
                class="dtb-show-table">
        <el-table-column label="活动主题"
                         prop="subject"></el-table-column>
        <el-table-column label="活动时间"
                         show-overflow-tooltip>
          <template slot-scope="scope">
            {{scope.row.start_date}}至{{scope.row.end_date}}
          </template>
        </el-table-column>
        <el-table-column label="活动地点"
                         prop="address"></el-table-column>
        <el-table-column label="手机号"
                         prop="mobile"></el-table-column>
        <el-table-column label="预定单位"
                         prop="company_name"></el-table-column>
        <el-table-column label="金额（元）"
                         prop="total_price"></el-table-column>
        <el-table-column label="提交人手机号"
                         prop="user_mobile"></el-table-column>
        <el-table-column label="状态">
          <template slot-scope="scope">
            {{scope.row.status === 1 ? '已提交' : '已完成'}}
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text"
                       @click="onItemClick(scope.row)">查看</el-button>
            <el-button type="primary"
                       class="export">
              <el-link :href="exportCSV(scope.row)"
                       type="text"
                       :style="{color:'white'}"
                       size="small">导出</el-link>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="dtb-table-pagination">
        <span>共{{ totalCount }}条记录</span>
        <el-pagination background
                       layout="prev, pager, next"
                       :current-page.sync="search.page_no"
                       :page-size="search.page_size"
                       :total="totalCount"></el-pagination>
      </div>
    </div>

    <el-dialog :title="'预定详情'"
               :visible.sync="isDlg"
               class="detail"
               width="75%">
      <div class="body">
        <div class="group">
          <div class="title">预定信息</div>
          <div class="items small-space">
            <div class="item flex">
              <div class="name">活动主题：</div>
              <div class="text">{{detail.subject}}</div>
            </div>
            <div class="item flex">
              <div class="name">填单人：</div>
              <div class="text">{{detail.committer}}</div>
            </div>
            <div class="item flex">
              <div class="name">手机号：</div>
              <div class="text">{{detail.mobile}}</div>
            </div>
            <div class="item flex">
              <div class="name">预定单位：</div>
              <div class="text">{{detail.company_name}}</div>
            </div>
            <div class="item flex">
              <div class="name">活动地址：</div>
              <div class="text">{{detail.address}}</div>
            </div>
            <div class="item flex">
              <div class="name">活动时间：</div>
              <div class="text">{{$dayjs(detail.start_date).format('YYYY-MM-DD HH:mm')}}~{{$dayjs(detail.end_date).format('YYYY-MM-DD HH:mm')}}</div>
            </div>
            <div class="item flex"
                 v-if="false">
              <div class="name">到场时间（摄影师）：</div>
              <div class="text">{{$dayjs('2021-09-01 ' + detail.arrival_date).format('HH:mm')}}</div>
            </div>
            <div class="item flex"
                 v-if="false">
              <div class="name">拍摄时间：</div>
              <div class="text">{{$dayjs('2021-09-01 ' + detail.arrival_start_time).format('HH:mm')}}~{{$dayjs('2021-09-01 ' + detail.arrival_end_time).format('HH:mm')}}</div>
            </div>
            <div class="item flex">
              <div class="name">现场人数（预计）：</div>
              <div class="text">{{detail.customer_count}}</div>
            </div>
            <div class="item flex">
              <div class="name">工作餐（摄影师）：</div>
              <div class="text">{{detail.is_dinner*1 === 1 ? '提供' : '不提供'}}</div>
            </div>
            <div class="item flex">
              <div class="name">选择我们的理由：</div>
              <div class="text">{{detail.reason | reasonName}}</div>
            </div>
            <div class="item flex">
              <div class="name">印象分：</div>
              <div class="text">{{detail.rate}}</div>
            </div>
            <div class="item flex">
              <div class="name">特殊要求：</div>
              <div class="text">{{detail.requirement}}</div>
            </div>
          </div>
        </div>
        <div class="group">
          <div v-for="(group,groupIndex) in detail.groups"
               :key="groupIndex">
            <div class="title">{{group.name}}：¥{{group.total_price}}</div>
            <div class="items big-space">
              <div class="big-item"
                   v-for="(child,childIndex) in group.children"
                   :key="childIndex">
                <div class="top">
                  <div class="unit">{{child.name}}（{{child.unit}}）</div>
                  <div class="price">{{child.price}}元</div>
                </div>
                <el-input v-model="child.value"
                          placeholder="请输入"
                          type="number"
                          size="small"
                          @input="updateState"></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-center">
        <div class="left">
          <div class="space">
            <div class="tips">状态</div>
            <div class="text">{{detail.status === 1 ? '已提交' : '已完成'}}</div>
          </div>
          <div class="space">
            <div class="tips">预定金额</div>
            <div class="text">¥ {{detail.total_price}}</div>
          </div>
        </div>
        <div class="right">
          <el-button type="primary"
                     @click="submit">确定</el-button>
          <el-button @click="isDlg = false">取消</el-button>
        </div>

      </div>
    </el-dialog>

  </div>
</template>

<script>
import PageHeader from '../../common/components/PageHeader.vue'
import exprEval from 'expr-eval'
import * as api from '../api/record'
export default {
  components: { PageHeader },
  data () {
    return {
      search: { subject: '', mobile: '', user_mobile: '', dates: [], status: '', page_no: 1, page_size: 10 },
      totalCount: 0,
      list: [],
      isDlg: false,
      selItem: {},
      detail: { groups: [] }
    }
  },
  mounted () {
    this.getList()
  },
  watch: {
    'search.page_no' () {
      this.getList()
    }
  },
  filters: {
    reasonName (val) {
      if (!val) {
        return ''
      }
      const reasonMap = ['', '服务专业', '价格合理', '想体验下']
      let list = val.split(',')
      let result = []
      list.forEach(item => {
        result.push(reasonMap[item])
      })
      return result.join(',')
    }
  },
  methods: {
    exportCSV (row) {
      if (this.$store.state.user) {
        const baseUrl = this.$store.state.api
        const token = this.$store.state.user.token
        return baseUrl + 'record/export?id=' + row.id + '&token=' + token;
      } else {
        return ''
      }
    },
    updateState () {
      console.log('updateState')
      let totalPrice = 0
      this.detail.groups.forEach((group) => {
        group.children = this.calItems(group.children)
        let groupPrice = 0
        group.children.forEach(child => {
          groupPrice += child.total_price
        })
        group.total_price = groupPrice
        totalPrice += groupPrice
      })
      this.detail.total_price = totalPrice
      this.detail = JSON.parse(JSON.stringify(this.detail))
    },
    calItems (items) {
      const paramsMap = new Map()
      items.forEach(item => {
        if (item.value) {
          if (item.mini_value && item.mini_value * 1 > item.value * 1) {
            paramsMap.set(item.params, item.mini_value)
          } else {
            paramsMap.set(item.params, item.value)
          }
        } else {
          paramsMap.set(item.params, 0)
        }
      })
      items.forEach(item => {
        let regex = item.regex
        paramsMap.forEach(function (value, key) {
          regex = regex.replace(key, value)
        })
        if (item.price === '费用另计') {
          item.total_price = 0
        } else {
          item.total_price = this.calCommonExp(regex)
        }
      })
      return items
    },
    calCommonExp (val) {
      return (exprEval.Parser.evaluate(val, {}))
      // return calCommonExp(val)
    },
    getList () {
      const params = { ... this.search }
      if (params.dates.length > 0) {
        params.start_date = params.dates[0]
        params.end_date = params.dates[1]
      }
      params.page_no = params.page_no - 1
      api.getList(params).then(({ data: { code, data } }) => {
        if (code === 200) {
          this.totalCount = data.total_count
          this.list = data.list
        }
      })
    },
    reset () {
      this.search = { subject: '', mobile: '', user_mobile: '', dates: [], status: '', page_no: 1, page_size: 10 }
    },
    onItemClick (row) {
      api.getDetail({ id: row.id }).then(({ data: { code, data } }) => {
        if (code === 200) {
          this.detail = data
          this.isDlg = true
        }
      })
      // this.isDlg = true
      this.selItem = row
    },
    submit () {
      const params = { ...this.detail }
      api.edit(params).then(({ data: { code } }) => {
        if (code === 200) {
          this.isDlg = false
          this.getList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  flex: 1;
  background: white;
  margin: 18px;
  padding: 18px;
  .add {
    margin: 24px;
    margin-top: 0;
    display: block;
  }
}
.detail {
  .body {
    background: #f0f2f5;
    padding: 24px;
    .group {
      background: white;
      margin-top: 24px;
      .title {
        padding: 16px 32px;
        text-align: left;
        border-bottom: 1px solid #f0f2f5;
        font-weight: 500;
        font-size: 16px;
        color: rgba(0, 0, 0, 0.85);
      }
      .items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 32px;
        padding: 32px;
        .item {
          font-size: 14px;
          .name {
            color: rgba(0, 0, 0, 0.85);
          }
          .text {
            color: rgba(0, 0, 0, 0.65);
          }
        }
        .big-item {
          .top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            margin-bottom: 8px;
            .unit {
              color: rgba(0, 0, 0, 0.85);
            }
            .price {
              color: #1890ff;
            }
          }
        }
        .flex {
          display: flex;
        }
      }
      .big-space {
        grid-row-gap: 32px;
      }
      .small-space {
        grid-row-gap: 16px;
      }
    }
    .group:first-child {
      margin-top: 0;
    }
  }
  .flex-center {
    background: white;
    justify-content: space-between;
    padding: 12px 24px;
    .left {
      display: flex;
      .space {
        margin-right: 56px;
        text-align: left;
      }
      .tips {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.45);
      }
      .text {
        margin-top: 4px;
        font-size: 20px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
}
</style>

<style lang="scss">
.el-dialog {
  &__body {
    border-top: 1px solid #eaeaea;
    background: #f0f2f5;
    max-height: 80%;
    padding: 0px;
  }
}
.record-header {
  background: black;
}
</style>