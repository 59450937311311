/*
 * @Author: your name
 * @Date: 2021-09-28 10:15:14
 * @LastEditTime: 2021-09-28 10:16:15
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \photo-admin\src\views\record\api\record.js
 */
import { get, post } from "../../../api/requests";

export const getList = data => {
  return get("record/list", data);
};

export const getDetail = data => {
  return get("record/detail", data);
};

export const edit = data => {
  return post("record/edit", data);
};
